import styled from '@emotion/styled';
import { graphql, PageProps } from 'gatsby';
import React from 'react';

import ArticleMeta from '../components/article-meta';
import Layout from '../components/layout';
import Pagination from '../components/pagination';
import SEO from '../components/seo';
import {
  asideTextColor,
  borderColor,
  linkColor,
  linkHoverColor,
  metaTextColor,
  pageDividerColor,
  tabletBreakpoint,
  titleColor,
} from '../design/css-variables';
import { EdgeNode } from '../types/data';
import { isNotUndefined } from '../utils/filter';

// できるだけ Vertical Rhythm に従うようにする
// 下記はremでの値。デスクトップでx10するとpxになるようにGlobalで設定してある。
const lineHeight = 3.2;
const h1FontSize = lineHeight;
const h1BorderWidth = 0.3;
const h1PaddingBottom = 0.7;
const h2FontSize = 2.4;
const h2BorderWidth = 0.1;
const h2PaddingBottom = lineHeight - h2FontSize - h2BorderWidth;
const h3FontSize = 1.8;
const h3MarginTop = lineHeight * 1.5; // 上部マージンの方を大きくする
const h3MarginBottom = lineHeight * 2 - h3MarginTop;
const baseFontSize = lineHeight / 2;
// コード部分は Vertical Rhythm 無視
const codeFontSize = 1.3;
const codeLineHeight = codeFontSize * 1.6;

const h2FontSizeSmartphone = h3FontSize;
const h2PaddingBottomSmartphone = lineHeight - h2FontSizeSmartphone - h2BorderWidth;

const Article = styled.article`
  font-size: ${baseFontSize}rem;
  line-height: ${lineHeight}rem;

  /* footnote のリンクは下線をなくして、代わりに色を目立たせる（リンク色とホバー色を逆転） */
  sup {
    a {
      box-shadow: none;
      color: ${linkHoverColor};
    }
    a:hover {
      color: ${linkColor};
    }
  }

  /* ヘッダーのアンカーリンクに下線を付けない */
  a.anchor {
    box-shadow: none;
  }

  /* スマホでは見出し部分の文字を小さく、マージンも少なめに */
  h1 {
    font-size: ${h2FontSize}rem;
    line-height: ${lineHeight}rem;
  }
  section h1 {
    border-bottom: solid ${h1BorderWidth}rem ${titleColor};
    padding-bottom: ${h1PaddingBottom}rem;
    /* 直前の章の最終要素の下マージンは相殺され、この要素の上マージンが使われる */
    margin-top: ${lineHeight * 2 - h1PaddingBottom - h1BorderWidth}rem;
    margin-bottom: ${lineHeight}rem;
  }
  /* 記事タイトルとメタ情報を表示する記事ヘッダ部分の下マージンはスマホでは狭くする */
  header {
    margin-bottom: ${lineHeight}rem;
  }
  /* 記事タイトルはスマホではPCより小さく */
  header h1 {
    font-size: ${h1FontSize}rem;
    line-height: ${lineHeight}rem;
  }
  /* スマホでは文字サイズを小さくする */
  h2 {
    /* 下線含めてlineHeightになる想定 */
    font-size: ${h2FontSizeSmartphone}rem;
    line-height: ${h2FontSizeSmartphone}rem;
    border-bottom: solid ${h2BorderWidth}rem ${metaTextColor};
    padding-bottom: ${h2PaddingBottomSmartphone}rem;
    /* 直前の章の最終要素の下マージンは相殺され、この要素の上マージンが使われる */
    margin-top: ${lineHeight * 2 - h2PaddingBottomSmartphone - h2BorderWidth}rem;
    margin-bottom: ${lineHeight}rem;
  }
  h3 {
    font-size: ${h3FontSize}rem;
    /* 直前の章の最終要素の下マージンは相殺され、この要素の上マージンが使われる */
    margin-top: ${h3MarginTop}rem;
    margin-bottom: ${h3MarginBottom}rem;
  }

  /* タブレット以上の場合の設定 */
  @media screen and (min-width: ${tabletBreakpoint}px) {
    h1 {
      font-size: ${h1FontSize}rem;
      line-height: ${lineHeight}rem;
    }
    section h1 {
      /* 直前の章の最終要素の下マージンは相殺され、この要素の上マージンが使われる */
      margin-top: ${lineHeight * 3 - h1PaddingBottom - h1BorderWidth}rem;
    }
    header {
      margin-bottom: ${lineHeight * 2}rem;
    }
    header h1 {
      font-size: ${h1FontSize * 1.2}rem;
      line-height: ${lineHeight * 1.5}rem;
    }
    h2 {
      /* 下線含めてlineHeightになる想定 */
      font-size: ${h2FontSize}rem;
      line-height: ${h2FontSize}rem;
      border-bottom: solid ${h2BorderWidth}rem ${metaTextColor};
      padding-bottom: ${h2PaddingBottom}rem;
      /* 直前の章の最終要素の下マージンは相殺され、この要素の上マージンが使われる */
      margin-top: ${lineHeight * 2 - h2PaddingBottom - h2BorderWidth}rem;
      margin-bottom: ${lineHeight}rem;
    }
    h3 {
      font-size: ${h3FontSize}rem;
      /* 直前の章の最終要素の下マージンは相殺され、この要素の上マージンが使われる */
      margin-top: ${h3MarginTop}rem;
      margin-bottom: ${h3MarginBottom}rem;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: ${lineHeight}rem;
    /*
    white-space: pre-wrap;
    */
  }

  ul,
  ol {
    margin-top: 0;
    p {
      margin-bottom: 0;
    }
    ul,
    ol {
      margin-bottom: 1em;
    }
  }
  section > ul,
  ol {
    margin-bottom: 2em;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: ${lineHeight}rem;

    td,
    th {
      border: 1px solid ${borderColor};
      border-width: 0 0 1px;
      padding: 0.5em 0.75em;
      vertical-align: middle; /* FIXME: 本当にこれがいい？ */
    }
    th {
      border-bottom-width: 0;
      background-color: ${pageDividerColor};
    }
    tr:nth-of-type(even) {
      background-color: #fafafa;
    }
  }

  .gatsby-highlight-title {
    font-size: ${codeFontSize}rem;
    line-height: ${codeLineHeight}rem;
    color: ${asideTextColor};
    background-color: ${pageDividerColor};
    display: table;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px 8px 0 0;
  }
  .gatsby-highlight {
    margin-bottom: ${lineHeight}rem;
  }
  pre {
    margin: 0;
    padding: 16px;
    line-height: ${codeLineHeight}rem;
  }
  code[class*='language-'] {
    font-size: ${codeFontSize}rem;
    line-height: ${codeLineHeight}rem;
  }

  /* 記事全体最下部の hr は表示しない。footnotes上の hr の表示は footnotes 内で設定。 */
  hr {
    border: none;
  }

  .footnotes {
    /* 注: 最後の要素の下マージンは相殺され、こちらが選択される */
    margin-top: ${lineHeight * 2}rem;
    margin-bottom: ${lineHeight * 2}rem;
    font-size: ${codeFontSize}rem;
    line-height: ${codeLineHeight}rem;
    color: ${asideTextColor};
    code[class*='language-'] {
      white-space: pre-wrap;
    }
    ol {
      padding-left: ${codeFontSize * 2}rem;
    }
    p {
      margin-bottom: 0;
    }
    hr {
      border: solid 1px ${pageDividerColor};
    }
    /* footnote の戻るリンクは下線をなくして、代わりに色を目立たせる（リンク色とホバー色を逆転） */
    a {
      box-shadow: none;
      color: ${linkHoverColor};
    }
    a:hover {
      box-shadow: 0 -1px 0px 0px inset;
      color: ${linkColor};
    }
  }
`;

export type PageContext = {
  slug: string;
  previous: EdgeNode | null;
  next: EdgeNode | null;
};

type Props = PageProps<GatsbyTypes.BlogPostBySlugQuery, PageContext>;

const BlogPostTemplate: React.FC<Props> = ({ data, pageContext }) => {
  const siteMetadata = data.site?.siteMetadata;
  const siteTitle = siteMetadata?.title || '';
  const siteDescription = siteMetadata?.description || '';

  const post = data.markdownRemark;
  const frontmatter = post?.frontmatter;
  const tags = frontmatter?.tags?.filter(isNotUndefined) || [];
  // TODO: undefined だったらデフォルト画像を用意して設定
  const imageUrl = frontmatter?.featuredimage?.childImageSharp?.fluid?.src;
  const pageTitle = frontmatter?.title || siteTitle;
  const pageDescription = frontmatter?.description || post?.excerpt || siteDescription;
  const postDate = frontmatter?.date || '不明';
  const updateDate = frontmatter?.updatedAt;
  const pageContent = post?.html || '';
  const tableOfContents = post?.tableOfContents;

  const { previous, next } = pageContext;
  const prevPageLink =
    previous === null
      ? null
      : {
          title: previous.frontmatter?.title || '前の記事へ',
          to: previous.fields?.slug || '/',
        };
  const nextPageLink =
    next === null
      ? null
      : {
          title: next.frontmatter?.title || '次の記事へ',
          to: next.fields?.slug || '/',
        };

  return (
    <Layout title={siteTitle} description={siteDescription} tableOfContents={tableOfContents}>
      <SEO title={pageTitle} description={pageDescription} ogType='article' imagePath={imageUrl} tags={tags} />
      <Article>
        <header>
          <h1>{pageTitle}</h1>
          <ArticleMeta postDate={postDate} updateDate={updateDate} tags={tags} />
        </header>
        <section dangerouslySetInnerHTML={{ __html: pageContent }} />
        <hr />
        <footer></footer>
      </Article>

      <Pagination prevPage={prevPageLink} nextPage={nextPageLink} />
    </Layout>
  );
};

export default BlogPostTemplate;

// FIXME: このページで site 情報を取得しなくても、ヘッダで static query をかければいいのでは？
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        tags
        date(formatString: "YYYY年M月D日")
        updatedAt(formatString: "YYYY年M月D日")
        description
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 600, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      tableOfContents
    }
  }
`;
